// 智能合约ABI
// const contractABI=[]
import { abi as usdtABI } from './abis/usdt';
import { abi as bctABI } from './abis/bct';
import { abi as kaishanABI } from './abis/kaishan';
import { abi as donateABI } from './abis/donate';
import { abi as budhiABI } from './abis/budhi';

// main
const usdtAddress = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';
const bctAddress = 'TWCkLtXc8nrLZP836jiAnLmbzVzQWaMhFL';
const kaishanAddress = 'TFF8PMqre7Ywwav1Td2p5gH9D7G56NfL1B';
const donateAddress = 'TM9JqbvboM9nZfpUHKQgdGVQUJkQTuPizL';
const budhiAddress = 'TUXFHsCxP1LjT3tt5GWh5bxwutuAUNBh51';

var gWalletAddress = '';
var gUsdtToken = {};
var gBctToken = {};

var gKaishanContract = {};
var gDonateContract = {};
var gBudhiContract = {};


/**
 * 初始化
 * @param {Object} callback 返回tronWeb
 */
function init(callback){
	if(window.tronWeb && gWalletAddress && gUsdtToken && gBctToken && gKaishanContract && gDonateContract && gBudhiContract){
		callback({gWalletAddress, gUsdtToken, gBctToken, gKaishanContract, gDonateContract, gBudhiContract});
	}else{
		let _itvl = setInterval(() => {
			if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
				clearInterval(_itvl);
				gWalletAddress = window.tronWeb.defaultAddress.base58;
				gUsdtToken = window.tronWeb.contract(usdtABI, usdtAddress);
				gBctToken = window.tronWeb.contract(bctABI, bctAddress);
				gKaishanContract = window.tronWeb.contract(kaishanABI, kaishanAddress);
				gDonateContract = window.tronWeb.contract(donateABI, donateAddress);
				gBudhiContract = window.tronWeb.contract(budhiABI, budhiAddress);
				callback({
					gWalletAddress, gUsdtToken, gBctToken, gKaishanContract, gDonateContract, gBudhiContract
				});
			}
		}, 50);
	}
	
	setTimeout(()=>{
		if(!window.tronWeb){
			console.log("請使用支持TRON的Dapp瀏覽器打開");
		}
	},3000);
}

// kaishan
function kaishanApproveToken(amount, token, callback) {
	if(token === 'bct'){
		gBctToken.increaseApproval(kaishanAddress, amount).send().then((res)=>{
			callback(res);
		});
	}else{
		gUsdtToken.increaseApproval(kaishanAddress, amount).send().then((res)=>{
			callback(res);
		});
	}
}
function getKaishanApprovedAmount(token, callback){
	if(token === 'bct'){
		gBctToken.allowance(gWalletAddress, kaishanAddress).call().then((res)=>{
			callback(res);
		});
	}else{
		gUsdtToken.allowance(gWalletAddress, kaishanAddress).call().then((res)=>{
			callback(res);
		});
	}
}
function getKaishanTokenRate(callback){
	gKaishanContract.rate().call().then((res)=>{
		callback(res);
	});
}
function buyBct(amount, callback) {
	gKaishanContract.buyBct(amount).send().then((res)=>{
		callback(res);
	});
}
function sellBct(amount, callback) {
	gKaishanContract.sellBct(amount).send().then((res)=>{
		callback(res);
	});
}
// donate
function donateApproveToken(amount, token, callback) {
	if(token === 'BCT'){
		gBctToken.increaseApproval(donateAddress, amount).send().then((res)=>{
			callback(res);
		});
	}else{
		gUsdtToken.increaseApproval(donateAddress, amount).send().then((res)=>{
			callback(res);
		});
	}
}
function getDonateApprovedAmount(token, callback){
	if(token === 'BCT'){
		gBctToken.allowance(gWalletAddress, donateAddress).call().then((res)=>{
			callback(res);
		});
	}else{
		gUsdtToken.allowance(gWalletAddress, donateAddress).call().then((res)=>{
			callback(res);
		});
	}
}
function donate(symbol, amount, oid, callback) {
	gDonateContract.donate(symbol, amount, oid).send().then((res)=>{
		callback(res);
	});
}
function donateWithdraw(symbol, amount, callback) {
	gDonateContract.withdraw(symbol, amount).send().then((res)=>{
		callback(res);
	});
}
// budhi
function budhiApproveToken(amount, callback) {
	gUsdtToken.increaseApproval(budhiAddress, amount).send().then((res)=>{
		callback(res);
	});
}
function getBudhiApprovedAmount(callback){
	gUsdtToken.allowance(gWalletAddress, budhiAddress).call().then((res)=>{
		callback(res);
	});
}
/**
 * 
 * @param {*} amount 
 * @param {*} t 1:購買土地2:進場修行3:升級土地4:升级修行
 * @param {*} i 土地或修行ID
 * @param {*} callback 
 */
function budhiDonate(amount, t, i, callback) {
	gBudhiContract.donate(amount, t, i).send().then((res)=>{
		callback(res);
	});
}
function budhiWithdraw(amount, callback) {
	gBudhiContract.withdraw(amount).send().then((res)=>{
		callback(res);
	});
}

//导出相应的方法
export default {
	init, 
	kaishanApproveToken, getKaishanApprovedAmount, getKaishanTokenRate, buyBct, sellBct,
	donateApproveToken, getDonateApprovedAmount, donate, donateWithdraw, budhiApproveToken, getBudhiApprovedAmount, budhiDonate, budhiWithdraw

}